var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-overlay',{attrs:{"show":_vm.overlay,"rounded":"sm"}},[_c('b-card',{staticClass:"mb-0"},[_c('router-link',{staticClass:"brand-logo",attrs:{"to":{ name: 'inicio-home' }}},[_c('b-img',{attrs:{"fluid":"","src":_vm.appLogoImage,"alt":"logo"}})],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('h3',{staticClass:"text-center mb-1"},[_vm._v("Registrarse")]),_c('b-form-group',{attrs:{"label":"Nombre de usuario","label-for":"form-username"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
return [_c('b-form-input',{attrs:{"id":"form-username","name":"form-username","placeholder":"Usuario"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('b-form-invalid-feedback',{attrs:{"state":!(errors.length > 0)}},[_vm._v(" Registra un nombre de usuario ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Correo electrónico","label-for":"form-email"}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"form-email","name":"form-email","placeholder":"correo@empresa.com"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('b-form-invalid-feedback',{attrs:{"state":!(errors.length > 0)}},[_vm._v(" Registra un correo electrónico valido ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Contraseña","label-for":"form-password"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"form-password","type":_vm.passwordFieldType,"name":"form-password","placeholder":"Contraseña"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":!(errors.length > 0)}},[_vm._v(" Registra una contraseña ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Confirmar contraseña","label-for":"form-password-confirm"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"form-password-confirm","type":_vm.passwordFieldType,"name":"form-password-confirm","placeholder":"Confirmar contraseña"},on:{"input":function () {_vm.password_confirmation = (_vm.form.password == _vm.form.password_confirmation)}},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.password_confirmation}},[_vm._v(" Confirma tu contraseña ")])]}}],null,true)})],1),_c('b-form-group',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
return [_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","unchecked-value":null,"name":"checkbox-1"},model:{value:(_vm.aceptar_condiciones),callback:function ($$v) {_vm.aceptar_condiciones=$$v},expression:"aceptar_condiciones"}},[_vm._v(" Acepto las "),_c('b-link',{attrs:{"to":{ name: 'terminos' },"target":"_blank"}},[_vm._v(" política de privacidad y términos y condiciones")])],1),_c('b-form-invalid-feedback',{attrs:{"state":!(errors.length > 0)}},[_vm._v(" Debes acepto las política de privacidad y términos y condiciones ")])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" Registrarse ")])],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("¿Ya tienes una cuenta?")]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('br'),_c('span',[_vm._v("¡Inciar sesión aqui!")])])],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v("Iniciar sesión con")])]),_c('div',{staticClass:"auth-footer-btn d-flex justify-content-center"},[_c('login-google')],1),_c('div',{staticClass:"mt-2"},[_c('center',[_c('soporte-whatsapp')],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }